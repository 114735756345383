body {
    font-family: 'Lato', sans-serif !important;
}
/* .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    transform: scale(1.5);
}
.modal-title {
    width: 100%;
} */
#graph {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar {
    background-color: #031b4d;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.05);
    min-height: 100vh;
    position: fixed;
    width: 220px;
    transition: all 0.3s ease;
    overflow: auto;
    height: 100%;
    scrollbar-width: none;
    padding-top: 10px;
    z-index: 1049;
}
.data-table {
    display: table-cell;
}
.top-element {
    z-index: 2000;
}
.sidebar::-webkit-scrollbar {
    display: none;
}
.collapse-sidebar {
    width: 60px;
}
.bp3-button span {
    pointer-events: none;
}
.bp3-toast-container {
    z-index: 1060 !important;
}
.outer-wrapper {
    width: 100%;
}

.bg-lightgrey {
    background-color: #f8fafc;
}

.comment-submit {
    border-radius: 4px;
    padding: 10px;
}

@media (min-width: 800px) {
    .outer-wrapper {
        width: 100%;
        padding-left: 0px;
    }
}

@media (min-width: 1024px) {
    .collapse-outer-wrapper {
        padding-left: 60px !important;
    }
    .outer-wrapper {
        padding-left: 220px;
        width: 100%;
        transition: all 0.3s ease !important;
    }
}

@media (max-width: 1024px) {
    .sidebar {
        display: none;
    }
}

/* CONTENT */

.content {
    /* margin-top: 2rem; */
    /* padding-bottom: 5rem; */
}
.flex-right-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-left-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tabv2 .bp3-tab-panel {
    margin-top: 0;
    border-top: none;
}

.tabv2 .bp3-tab-list {
    border-bottom: 2px solid #eee;
    background: #fff;
}

@media (max-width: 600px) {
    .tabv2 .bp3-tab-list {
        overflow-x: scroll !important;
        overflow-y: hidden;
    }
}

.tabv2 .bp3-tab {
    font-size: 1rem;
    font-weight: 500;
    padding: 8px 16px;
}

.tabv2 .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: #5f90d5;
}

.tabv2 .bp3-tab[aria-selected='true'],
.tabv2 .bp3-tab:not([aria-disabled='true']):hover {
    color: #5f90d5;
}

.tabv2 .bp3-tab-indicator-wrapper {
    top: 2px;
}

/* SIDEBAR */

.sidebar-top-section {
    position: relative;
    padding: 19px 20px;
    transition: all 0.3s ease;
}
.sidebar-top-section-collapse {
    padding: 19px 9px !important;
}

.add-property-btn {
    position: absolute;
    float: right;
    right: 20px;
    transition: all 0.3s ease;
    opacity: 1;
}
.btn-back {
    background: transparent;
    border: 0;
    color: #127cbd;
    transition: all 0.3s;
}
.btn-back:hover {
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 600;
}
.add-property-btn-collapse {
    opacity: 0 !important;
    z-index: -1;
}

.sidebar__logo {
    height: 36px;
    width: 35px;
    max-width: none;
    transition: all 0.3s ease;
}
.sidebar__logo__collapse {
    height: 43px !important;
    width: 42px !important;
    max-width: none;
}

.sidebar__icon-plus {
    opacity: 0.7;
}

.sidebar__icon-plus:hover {
    opacity: 1;
    cursor: pointer;
}

.sidebar-navigation-items {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.sidebar-navigation-items-collapse {
    padding-top: 1.5rem;
    border-top: 1px solid rgba(180, 188, 199, 0.32);
}

.sidebar-navigation-item {
    color: #fff;
    display: block;
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0 4px 4px 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
    opacity: 0.9;
}

.custom-select {
    background: #fff url(../svg/caret-down.svg) no-repeat right 0.75rem center;
    background-size: 8px;
}

.sidebar-navigation-item:hover {
    background-color: #01112f;
    color: #fff;
    text-decoration: none;
    opacity: 1;
}

.sidebar-navigation-item.active {
    color: #fff;
    background: #01112f;
    opacity: 1;
    border-radius: 0px;
}

.sidebar-navigation-item-icon {
    right: 20px;
    margin-right: 20px;
    margin-left: -40px;
}

.sidebar-navigation-section > h5 {
    text-transform: uppercase;
    color: #677694;
    font-weight: 600;
    padding: 25px 0px 10px 20px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    letter-spacing: 0.06rem;
    -webkit-transition: padding 0s linear 0.4s;
    -o-transition: padding 0s linear 0.4s;
    transition: padding 0s linear 0.4s;
    cursor: pointer;
}

.sidebar-navigation-section {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(180, 188, 199, 0.32);
}

/* ***********************
*         BUTTONS        *
**************************/
.btn i,
i {
    pointer-events: none;
}

.btn {
    transition: all 0.3s;
    letter-spacing: 0px;
}

.btn-transparent {
    background: transparent;
    outline: 0;
}

.btn-actions {
    background-color: #4b789f;
    color: #fff;
}

.btn-bordered {
    border: 1px solid #1287c4;
    font-weight: 400;
    font-size: 15px;
    color: #5d5d5d;
    transition: all 0.3s;
}

.btn-bordered i {
    margin-right: 5px;
}

.btn-bordered:hover {
    transform: scale(1.05);
}

/* ***********************
*       CONTACTS         *
**************************/

.actions-item {
    display: inline-block;
    padding-top: 5px;
    padding-right: 2rem;
}

.actions-btn {
    display: inline-block;
    margin-top: -0.8rem;
}

.card__profile {
    padding: 2rem;
}

.card__profile--company {
    margin-top: -0.5rem;
}

.card__profile--title {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.card__profile--item {
    opacity: 0.5;
    padding-top: 1rem;
    margin-bottom: 0;
}

/* ***********************
*   PROPERTIES CARDS     *
**************************/

.card__property-description {
    padding: 0.75rem;
}

.card__property-title {
    font-weight: 700;
    color: #06b1d7;
}

.card__property-price {
    font-size: 1.2rem;
    font-weight: 700;
    display: inline-block;
}

.card__property-listingType {
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    padding-left: 1rem;
    font-size: 0.7rem;
    opacity: 0.5;
}

.card__property-location {
    font-size: 0.9rem;
    opacity: 0.6;
}

.card__property-ammenities {
    display: inline-block;
    font-size: 0.9rem;
    opacity: 0.6;
    cursor: default !important;
}

.card__property-ammenities-item {
    margin-left: 15px;
    display: inline-block;
}

.card__property-ammenities-item:first-child {
    margin-left: 0px;
}

/* PROPERTIES DETAILS */

.property-specs span {
    margin-left: 0.4rem;
    cursor: default;
}

.card__property-sliderContainer {
    padding: 0px;
    cursor: -webkit-grab;
}

.card__property-sliderContainer:active {
    padding: 0px;
    cursor: -webkit-grabbing;
}

.card__property-slider--Image {
    width: 100%;
    background-size: cover;
    max-height: 500px;
}

.card__property-details-aeId {
    font-size: 0.8rem;
    opacity: 0.7;
}

.card__property-details-name {
    font-size: 1.1rem;
    letter-spacing: -0.5px;
}

.card__property-details-price {
    font-size: 1.5rem;
    display: inline-block;
    font-weight: 700;
    display: block;
    cursor: default;
}

.card__property-details-price span.small {
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
    letter-spacing: 2px;
    margin-top: 2px;
}

.card__property-detailsCard {
    background-color: #f5f7fb;
    color: #7a7a7a;
    padding: 1rem;
    border-radius: 4px;
}

.card__property-details-listingType {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-left: 10px;
}

.card__property-details-section {
    font-size: 1.2rem;
}

.share-btn {
    margin-top: 1rem;
    width: 100%;
}

.share-btn:first-child {
    margin-top: 0rem;
}

.features-list {
    padding-left: 0;
    list-style: none;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.colorBlue {
    color: #007cab;
}

.features-list li i {
    font-size: 0.8rem;
}

.property-feature-desc {
    font-weight: 700;
    text-transform: uppercase;
}

/* End Properties Details */

/* PROPERTIES SEARCH */

.row-search {
    background-color: #fff;
    padding: 10px 10px 10px 22px;
    width: 100%;
    margin-top: -2rem;
    margin-bottom: 2rem;
}

.search__filter {
    display: inline-block;
    margin-left: 1rem;
}

.search__filter:first-child {
    margin-left: 0rem;
}

.content-fields {
    padding: 10px 20px;
}

.card-container {
    padding: 2rem 1rem 2rem 1rem;
}

/* ***********************
*     UTILITIES          *
**************************/
@media (min-width: 576px) {
    .container {
        max-width: 900px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 992px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1440px;
    }
}
.listnone {
    padding: 0 !important;
    list-style: none !important;
}
.no-gutter {
    padding: 0 !important;
}
.text-align-right {
    text-align: right !important;
}
.bringTop {
    z-index: 90000 !important;
}
.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.min50 {
    min-height: 65vh;
}

.link {
    color: #317ae2;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}
@media (min-width: 1000px) {
    .text-right-lg {
        text-align: right !important;
    }

    .control-label {
        padding-top: 7px;
    }
}

.fs-1 {
    font-size: 1rem;
}

.danger {
    color: rgb(255, 0, 0);
}
.danger-circle {
    background: #ff00009e;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-left: 7px;
}
.inline-block {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.pgrey {
    color: #7a7a7a;
}

.color-primary {
    color: rgb(3, 149, 182);
}

/* Tabler React */

.nav-tabs {
    margin: 0;
}

.ql-editor {
    min-height: 100px;
}

.quill {
    background-color: #fff;
}

.card-design-properties {
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    background-color: #fff;
}

.icon-amenitie-sector {
    cursor: pointer;
}

.requerido {
    padding: 0;
}

.selectgroup-item {
    margin-bottom: 0 !important;
}
.active-search {
    background: #fff;
}
.active-search:active {
    background: #316cbe;
}
.rdw-editor-main {
    min-height: 300px;
    border: 1px solid #f3f3f3;
}

.property-image-card {
    position: relative;
    float: left;
    width: 100% !important;
    height: 250px !important;
}

.loading-container {
    position: fixed;
    float: left;
    left: 0%;
    top: 0%;
    left: 220px;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.9);
    height: 100%;
    width: calc(100% - 220px);
    text-align: center;
    border-radius: 5px;
}

.content {
    position: relative;
}

.loading {
    position: relative;
    float: left;
    width: 100%;
    top: 25%;
    text-align: center;
}

.loading-image {
    position: relative;
    float: left;
    width: 115px;
    left: calc(50% - 55px);
    margin-top: 25px;
}

@media screen and (max-width: 1023px) {
    .loading-container {
        left: 0px !important;
        width: 100% !important;
    }
}

.agent-fullName {
    font-weight: 700;
    font-size: 1.2rem;
}

#agent-card {
    transition: all 0.3s;
}

#agent-card:hover {
    transform: translateY(-1px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.062);
}

.form-group-sm {
    padding-bottom: 5px;
}

.form-footer {
    border-top: 1px solid #eee;
    padding-top: 1rem;
}

.social-links-contact > li {
    list-style: none;
    display: inline-block;
    margin-right: 0.5rem;
}

.social-links-contact > li > a {
    text-decoration: none;
    color: #000;
    text-align: center;
    display: inline-block;
    height: 1.75rem;
    width: 1.75rem;
    line-height: 1.75rem;
    text-align: center;
}

.social-links-contact {
    padding-left: 0rem;
    display: inline-block;
    margin-top: 0.5rem;
}

.social-links-contact > ul {
    padding-left: 0rem;
}

.remove-picture {
    position: absolute;
    z-index: 999;
    border-radius: 50px;
    background: #fff;
    color: #000;
    cursor: pointer;
    border: solid 1px #eee;
    box-shadow: 0 0 3px #000;
    top: 5px;
    left: 5px;
    display: none;
    outline: none !important;
}

.imagecheck:hover button {
    display: block;
}

.imagecheck i {
    pointer-events: none;
}

.amenities-checklist {
    list-style: none;
    padding-left: 0;
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
}

.hot {
    color: #fff;
    background-color: red;
    font-size: 9px;
    font-weight: 800;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    position: absolute;
    margin-top: 6px;
    margin-left: 5px;
    border-radius: 2px;
}
.ReactModal__Overlay {
    z-index: 1200 !important;
}
/* @media (min-width: 992px) {
    .modal-xl {
        max-width: 80vw;
    }
} */
.modal-nm {
    margin-top: 0 !important;
}
/* Modal Fixed Fullwidth */
/* MODAL FIXED */
.modal-full {
    min-width: 100%;
    margin: 0;
}
.modal-full .modal-content {
    min-height: 100vh;
}
@media (min-width: 992px) {
    .modal-xl {
        max-width: 80vw;
    }
}
.modal-full .modal-header {
    position: fixed;
    top: 0;
    z-index: 9000000 !important;
    width: 100%;
    background: #ffff;
    left: 12px;
}
.modal-full .modal-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9000000 !important;
    background: #ffff;
    padding: 10px;
    border-radius: 0px;
}
.modal-full .modal-body {
    height: 300px;
    overflow: scroll;
    padding-top: 70px;
    padding-bottom: 70px;
}
/* PAGINATION */
.page-link.selected {
    background-color: #008abd;
    color: #fff;
    font-weight: 600;
    outline: none;
}
@media (max-width: 600px) {
    .pagination {
        margin-bottom: 0px !important;
    }
}
.btn-primary {
    color: #fff;
    background-color: #339ee9;
    border-color: #339ee9;
}
.btn-pagination {
    background: rgb(243, 243, 243);
    color: #031b4d;
    margin-left: 1rem;
    border: 1px solid rgb(209, 209, 209);
}
.btn-outline-primary {
    display: flex;
    align-items: center;
    color: #656565;
}
.btn-outline-primary.active {
    background: #467fcf;
    color: #fff;
}
.btn-outline-primary svg {
    margin-right: 5px;
    color: #467fcf;
}
.btn-outline-primary:hover svg,
.btn-outline-primary.active svg {
    margin-right: 5px;
    color: white;
    pointer-events: none;
}
.btn-dropdown {
    padding: 5px 8px 0px 8px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
    cursor: pointer;
}

.btn-dropdown:hover {
    background-color: #f7f7f7;
}

.btn-dropdown:active {
    background-color: #eee;
}

.btn-pagination:hover {
    background-color: #031b4d;
    color: #fff;
    border: 1px solid #031b4d;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.075);
}

.disabled {
    opacity: 1 !important;
}

.table-not-found {
    display: block;
    width: 100%;
    margin-top: 1rem;
    position: absolute;
    text-align: center;
}

.property-badge {
    display: inline-block;
}

.property-badge.true {
    background-color: yellowgreen;
    color: #fff;
    margin-left: 1rem;
    font-size: 0.8rem;
    font-weight: 900;
    position: absolute;
    top: 0px;
    border-top-right-radius: 3px;
    right: 0px;
    padding: 2px 9px;
}

.property-badge.false {
    background-color: red;
    color: #fff;
    margin-left: 1rem;
    font-size: 0.8rem;
    font-weight: 900;
    position: absolute;
    top: 0px;
    border-top-right-radius: 3px;
    right: 0px;
    padding: 2px 9px;
}

.slick-prev {
    z-index: 9 !important;
    left: 8px;
    transform: scale(1.2);
    opacity: 0.5;
    transition: all 0.3s;
}
.slick-dots {
    bottom: 20px !important;
}
.slick-dots li button:before {
    font-size: 9px;
    color: #fff !important;
}
.slick-next {
    opacity: 0.5;
    right: 8px;
    z-index: 10;
    transform: scale(1.2);
    transition: all 0.3s;
}

.slick-prev:hover,
.slick-next:hover {
    opacity: 1;
}

select option:disabled {
    color: red;
}

form.property-form span.bp3-popover-target {
    width: 100%;
}

.overview__container:first-child {
    padding-top: 0;
}

.overview__container {
    padding-top: 0.6rem;
}

.overview__title {
    opacity: 0.7;
}

.overview__data {
    color: #031b4d;
}

.overview__data ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0;
}

.grey {
    color: #7d7d7d;
}

.green {
    color: green;
}

.rc-select-selection--multiple {
    min-height: 2.38rem;
    border-radius: 2px;
    border-color: #e8eaf0;
}

.rc-select-selection__placeholder {
    top: 2px;
}

.rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
    margin-top: 7px;
    line-height: 23px;
}

.rc-select-selection {
    border: 1px solid #e8eaf0 !important;
    border-radius: 3px !important;
}

.header {
    padding-left: 10px;
    padding-right: 15px;
    border-bottom: 1px solid #f1f1f1;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.03);
}
@media only screen and (max-width: 1024px) {
    .header-toggler {
        display: flex;
    }
}
@media only screen and (min-width: 1025px) {
    .header-toggler {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        position: fixed;
        width: 100%;
        z-index: 10;
        top: 0;
    }

    .header-margin {
        padding-bottom: 4rem;
    }
}

/*==================================
=      SIDEBAR MOBILE
====================================*/

.backdrop {
    position: fixed;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    width: 100vw;
    z-index: 1000 !important;
    height: 100vh;
}

.sidebar-mobile {
    position: absolute;
    z-index: 2000 !important;
    color: #fff;
    left: 0;
    top: 0;
    width: 60vw;
    background-color: #031b4d;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.05);
    min-height: 100vh;
    position: fixed;
    min-width: 220px;
}

button.goback {
    position: fixed;
    bottom: 10%;
    background-color: #fff;
    z-index: 10000000000;
    width: 40vw;
    padding: 10px;
    color: #000;
    border-top: 2px solid #007cab;
    border-right: 2px solid #007cab;
    border-bottom: 2px solid #007cab;
    font-size: 1rem;
    font-weight: 600;
    left: 0;
    /* box-shadow: 0px 0px 10px #00000045; */
    outline: none;
}

/*==================================
=      TYPOGRAPHY
====================================*/
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
.lightblue {
    color: #06b1d7 !important;
}
.strong {
    font-weight: 600;
}
.help-paragraph {
    opacity: 0.5;
}
/*==================================
=      MOBILE FILTER
====================================*/

.filter-mobile {
    position: fixed;
    z-index: 4000 !important;
    color: #000;
    left: 0;
    top: 0;
    width: 100vw;
    min-width: 100vw;
    background-color: #fff;
    min-height: 100vh;
    min-width: 220px;
}

.filter-mobile .title {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    border-bottom: 1px solid #e7e9e9;
    color: #2f3641;
}

.title .big {
    font-weight: 700;
    font-size: 1.2rem;
}

.filter-mobile-container {
    padding: 1.3rem;
    overflow: scroll;
    max-height: 680px;
    padding-bottom: 6rem;
}

.filter-mobile .apply {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    color: #fff;
    border-top: 1px solid #e3e5e6;
    padding: 1rem;
    z-index: 90;
}

.filter-mobile .title .cancel {
    opacity: 0.4;
    font-size: 1.2rem;
}

.filter-mobile .title .delete {
    color: #031b4d;
    padding-top: 3px;
}

button.apply-filter {
    width: 100%;
    border: 0;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    background-image: linear-gradient(to right, #031b4d, rgb(2, 40, 121));
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    outline: none;
    transform: all 0.3s;
}

.filter-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-btn button {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
}

@media (max-width: 600px) {
    .row-search {
        background-color: #001950;
        position: fixed;
        bottom: 0;
        margin-bottom: 0;
        z-index: 90000;
    }

    .pagination {
        margin-bottom: 5rem !important;
    }

    .logo-mobile {
        position: absolute;
        align-items: center;
        justify-content: center;
        display: flex;
        left: 31%;
        bottom: 12px;
        height: 40px;
    }

    .logo-mobile img {
        height: 30px;
    }
}

.status-filter {
    display: inline-block !important;
}

.status-filter button {
    background-color: #fff;
    border: 1px solid #4c525a;
    margin-right: 5px;
    outline: none;
    transition: all 0.3s;
    cursor: pointer;
}

.status-filter button.active {
    background-color: #001950;
    color: #fff;
    border: 1px solid #001950;
}
.bp3-toast-container {
    z-index: 500;
}

/*==================================
=      MULTI-SELECT (REACT-SELECT)
====================================*/
.react-select__control {
    border-radius: 2px !important;
    border-color: #e0e5ed !important;
}
@media only screen and (max-width: 600px) {
    .react-select__control {
        font-size: 16px;
    }
}
.react-select__control--is-focused {
    border-color: #1991eb !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(25, 145, 235, 0.5) !important;
}

/*==================================
=      RECOMMENDATIONS
====================================*/

.recommendations {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-top: 10px;
}

.recommendations .budget {
    margin-bottom: 1rem;
}

.recommendations .price {
    font-weight: 600;
}

/*==================================
=      SORT TABLE
====================================*/
.sortable svg {
    pointer-events: none;
}
.d-inline {
    display: inline-block !important;
}
.sortTable {
    border: 1px solid #eee;
}

.sortTable .active {
    background-color: #e5e5e5;
}

.sortTable th span {
    display: inline-block;
}

.sortTable .sortable {
    cursor: pointer;
}
.d-inline {
    display: inline-block;
}
.profile .bp3-tab-list {
    padding-left: 15px;
    padding-top: 7px;
}
.bp3-tab[aria-selected='true'],
.bp3-tab:not([aria-disabled='true']):hover {
    outline: 0;
}
.bp3-tab-panel {
    margin-top: 8px;
    border-top: 1px solid rgba(0, 40, 100, 0.12);
}
.profile .documents {
    margin: 10px;
}
.documents {
    border: 1px solid #eee;
}
.document--item {
    padding: 5px 0px 5px 10px;
    border-bottom: 1px solid #eee;
    transition: all 0.3s;
}
.document--item.won {
    border-left: 2px solid #4caf50;
}
.won {
    color: #4caf50 !important;
}
.lost {
    color: #f94839 !important;
}
.white {
    background: #fff !important;
}
.document--item.open {
    border-left: 2px solid #1991eb;
}
.document--item.lost {
    border-left: 2px solid red;
}
.document--item:hover {
    box-shadow: 0px 0px 5px #00000012;
}
.document--item a,
.document--item button {
    color: rgb(44, 44, 44);
    opacity: 0.5;
    transition: all 0.3s;
}
.document--item a:hover,
.document--item button:hover {
    color: rgb(44, 44, 44);
    opacity: 1;
}
.additional-fields {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: all 0.3s;
}
.additional-fields:active {
    background-color: #eee;
}
/* Activity */

.bordered {
    padding: 10px 25px;
    border: 1px solid #f3f3f3;
}

.bordered a {
    margin-left: 15px;
    color: #007cab !important;
}

.flex-row {
    flex-direction: row !important;
}

/*==================================
=      Fade In Effect css
====================================*/
@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadein {
    animation: fadein 0.5s !important;
}
/*==================================
=      Activity Form
====================================*/
/* .content-daypicker .DayPicker {
    transform: scale(.9) !important;
} */
.btn-action {
    box-shadow: none !important;
    outline: none !important;
    padding: 7px 5px 0px 5px !important;
    margin-bottom: 1rem;
    border-radius: 0px !important;
    border-right: none !important;
}
.btn-action:first-child {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
}
.btn-action:last-child {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    border-right: 1px solid rgba(0, 40, 100, 0.12) !important;
}
.btn-action svg {
    pointer-events: none !important;
}
/*==================================
=      DAYPICKER
====================================*/
.bp3-datepicker {
    position: relative;
    border-radius: 3px;
    background: #fff;
    padding: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bp3-datepicker .DayPicker {
    display: inline-block;
    position: relative;
    min-width: 210px;
    vertical-align: top;
}
.bp3-datepicker .DayPicker:focus {
    outline: none;
}
.bp3-datepicker .DayPicker-Month {
    display: inline-table;
    margin: 0 5px 5px;
    border-collapse: collapse;
    border-spacing: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bp3-datepicker .DayPicker-Month + .bp3-datepicker .DayPicker-Month {
    margin-left: 10px;
}
.bp3-datepicker .DayPicker-Caption {
    display: table-caption;
}
.bp3-datepicker .DayPicker-Weekdays {
    display: table-header-group;
}
.bp3-datepicker .DayPicker-WeekdaysRow {
    display: table-row;
}
.bp3-datepicker .DayPicker-Weekday {
    display: table-cell;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
    padding-top: 5px;
    font-weight: 600;
}
.bp3-datepicker .DayPicker-Weekday abbr[title] {
    text-decoration: none;
}
.bp3-datepicker .DayPicker-Body {
    display: table-row-group;
}
.bp3-datepicker .DayPicker-Week {
    display: table-row;
}
.bp3-datepicker .DayPicker-WeekNumber {
    color: rgba(92, 112, 128, 0.6);
    font-size: 14px;
}
.bp3-datepicker .DayPicker-Day,
.bp3-datepicker .DayPicker-WeekNumber {
    display: table-cell;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
}
.bp3-datepicker .DayPicker-Day {
    border-radius: 3px;
    cursor: pointer;
}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--outside {
    color: rgba(92, 112, 128, 0.6);
}
.bp3-datepicker .DayPicker-Day:focus,
.bp3-datepicker .DayPicker-Day:hover {
    background: #d8e1e8;
    color: #182026;
}
.bp3-datepicker .DayPicker-Day:active {
    background: #ced9e0;
}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
    border-radius: 3px;
    background-color: #137cbd;
    color: #fff;
}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #106ba3;
}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
}
.bp3-datepicker-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30px;
}
.bp3-datepicker-navbar > .DayPicker-NavButton--prev {
    margin-right: auto;
}
.bp3-datepicker-navbar > .DayPicker-NavButton--next {
    margin-left: auto;
}
.bp3-datepicker-caption {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 25px 5px;
}
.bp3-datepicker-caption > * {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.bp3-datepicker-caption > :first-child {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}
.bp3-datepicker-caption select {
    padding-right: 16px;
    padding-left: 5px;
    font-weight: 600;
}
.bp3-datepicker-caption select + .bp3-icon {
    right: 2px;
}
.bp3-datepicker-caption + .bp3-divider {
    margin: 0;
}
.bp3-datepicker-month-select,
.bp3-datepicker-year-select {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}
.bp3-datepicker-year-select {
    min-width: 60px;
}
.bp3-datepicker-caption-measure {
    padding-left: 5px;
    font-weight: 600;
}
.bp3-datepicker-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.bp3-dark .bp3-datepicker {
    background: #30404d;
}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--outside,
.bp3-dark .bp3-datepicker .DayPicker-WeekNumber {
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark .bp3-datepicker .DayPicker-Day:focus,
.bp3-dark .bp3-datepicker .DayPicker-Day:hover {
    background: #5c7080;
    color: #fff;
}
.bp3-dark .bp3-datepicker .DayPicker-Day:active {
    background: #738694;
}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
    background-color: #137cbd;
}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #2b95d6;
}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled {
    background: none;
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark .bp3-datepicker .bp3-datepicker-footer {
    border-top-color: rgba(16, 22, 26, 0.4);
}
.bp3-daterangepicker {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
}
.bp3-daterangepicker .DayPicker:first-of-type .bp3-datepicker-navbar {
    left: 5px;
}
.bp3-daterangepicker .DayPicker-NavButton--interactionDisabled {
    display: none;
}
.bp3-daterangepicker .bp3-daterangepicker-timepickers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}
.bp3-daterangepicker.bp3-daterangepicker-contiguous .DayPicker {
    min-width: 220px;
}
.bp3-daterangepicker.bp3-daterangepicker-single-month .DayPicker {
    min-width: 210px;
}
.bp3-daterangepicker .DayPicker-Day--outside {
    visibility: hidden;
}
.bp3-daterangepicker .DayPicker-Day--hovered-range {
    border-radius: 0;
}
.bp3-daterangepicker
    .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
    background-color: #ebf1f5;
}
.bp3-daterangepicker .DayPicker-Day--selected-range {
    border-radius: 0;
    background-color: #ebf1f5;
}
.bp3-daterangepicker .DayPicker-Day--selected-range:hover {
    background-color: #ced9e0;
}
.bp3-daterangepicker
    .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.bp3-daterangepicker
    .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.bp3-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.bp3-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.bp3-dark
    .bp3-daterangepicker
    .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
    background-color: #394b59;
}
.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range {
    background-color: #394b59;
}
.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range:hover {
    background-color: #5c7080;
}
.bp3-menu.bp3-daterangepicker-shortcuts {
    min-width: 120px;
    padding: 0;
}
.bp3-timepicker {
    white-space: nowrap;
}
.bp3-timepicker .bp3-timepicker-arrow-row {
    padding: 0 1px;
}
.bp3-timepicker .bp3-timepicker-arrow-button {
    color: #5c7080;
    display: inline-block;
    width: 33px;
    padding: 4px 0;
    text-align: center;
}
.bp3-timepicker .bp3-timepicker-arrow-button:hover {
    color: #182026;
}
.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button {
    color: #a7b6c2;
}
.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button:hover {
    color: #f5f8fa;
}
.bp3-timepicker .bp3-timepicker-arrow-button + .bp3-timepicker-arrow-button {
    margin-left: 11px;
}
.bp3-timepicker .bp3-timepicker-arrow-button:hover {
    cursor: pointer;
}
.bp3-timepicker .bp3-timepicker-input-row {
    display: inline-block;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #fff;
    height: 30px;
    padding: 0 1px;
    vertical-align: middle;
    line-height: 28px;
}
.bp3-timepicker .bp3-timepicker-divider-text {
    display: inline-block;
    width: 11px;
    text-align: center;
    color: #5c7080;
    font-size: 16px;
}
.bp3-timepicker .bp3-timepicker-input {
    outline: 0;
    border: 0;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
    background: transparent;
    width: 33px;
    height: 28px;
    padding: 0;
    text-align: center;
    color: #182026;
    -webkit-transition: -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp3-timepicker .bp3-timepicker-input:focus {
    -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3);
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3);
}
.bp3-timepicker .bp3-timepicker-ampm-select {
    margin-left: 5px;
}
.bp3-timepicker.bp3-disabled .bp3-timepicker-input-row {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
    resize: none;
}
.bp3-timepicker.bp3-disabled .bp3-timepicker-divider-text,
.bp3-timepicker.bp3-disabled .bp3-timepicker-input {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
}
.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button,
.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button:hover {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
}
.bp3-dark .bp3-timepicker .bp3-timepicker-input-row {
    -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
}
.bp3-dark .bp3-timepicker .bp3-timepicker-divider-text {
    color: #a7b6c2;
}
.bp3-dark .bp3-timepicker .bp3-timepicker-input {
    color: #f5f8fa;
}
.bp3-datepicker .bp3-timepicker {
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
}
.bp3-datepicker .bp3-timepicker:last-child {
    margin-bottom: 5px;
}
.bp3-datetimepicker {
    border-radius: 3px;
    background-color: #fff;
    padding: 10px;
    text-align: center;
}
.bp3-dark .bp3-datetimepicker {
    background: #30404d;
}
.bp3-dark .bp3-datetimepicker .bp3-datepicker {
    border-bottom: 1px solid rgba(16, 22, 26, 0.4);
}
.bp3-datetimepicker .bp3-datepicker {
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    padding: 0 0 10px;
}
.bp3-datetimepicker .bp3-timepicker {
    margin-top: 10px;
}
.bp3-dateinput-popover {
    padding: 0;
}
/*==================================
=      DRAGGABLE
====================================*/
.funnel__element {
    background: red;
    color: #fff;
}
/*==================================
=      ACTION ICON CARD COMMENT
====================================*/
.center-trash {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.flex-center {
    display: flex;
    justify-content: center;
}
.icon-action {
    border-radius: 50%;
    border: solid 1px #f5f5f5;
    /* background: rgb(101, 170, 226); */
}
.svg-icon-action {
    color: rgb(101, 170, 226);
    fill: #fff;
}
.agent-icon-activity {
    position: absolute;
    float: right;
    right: 20px;
    bottom: 20px;
}
.close:before {
    content: '' !important;
}
@media only screen and (max-width: 600px) {
    .form-control {
        font-size: 16px !important;
    }
}
.form-control select {
    -webkit-appearance: none;
}
.input-phone {
    border: solid 1px #eee;
    height: 36px;
    border-radius: 0;
    padding: 5px;
    outline: none;
    position: relative;
    float: left;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .deal *,
    .title-stage * {
        font-size: 13px;
    }
}
@media screen and (max-width: 768px) {
    .deal *,
    .title-stage * {
        font-size: 11px;
    }
}
.activity-indicator i {
    font-size: 12px;
}
.activity-indicator.warning {
    color: #ffb90e;
}
.activity-indicator.danger {
    color: #ff6e6e;
}
.activity-indicator.ok {
    color: #ababab;
}
.title-stage {
    display: inline-block;
}
.content-title-stage {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    z-index: 9000;
}
.content-title-stage #arrow {
    position: absolute;
    top: 0;
    margin-left: -18px;
}
.content-stages #arrow:first-child {
    display: none;
}
.content-title-stage #arrow svg {
    max-height: 53px;
}
.content-stages {
    display: flex;
    flex-flow: column wrap;
    overflow: auto;
}
.target-controller {
    min-width: 150px;
    height: 45px;
    display: inline-block;
    width: 25%;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding-top: 5px;
}
.target-controller.lost {
    color: #fff;
    background-color: red;
    border-color: #da0000;
}
.target-controller.won {
    color: #fff;
    background-color: green;
    border-color: #007100;
}
.target-controller.trash {
    color: grey;
    background-color: #eee;
    border-color: #e2e2e2;
}
.sidebar-toggle {
    display: none;
}
@media only screen and (max-width: 991px) {
    .sidebar-toggler {
        display: block;
    }
}
.recipe-toaster {
    z-index: 99999;
}
.status-filter {
    display: inline-block !important;
}
.status-filter button {
    background-color: #fff;
    border: 1px solid #4c525a;
    margin-right: 5px;
    outline: none;
    transition: all 0.3s;
    cursor: pointer;
}
.status-filter button.active {
    background-color: #001950;
    color: #fff;
    border: 1px solid #001950;
}
.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.actions-container {
    margin-top: -2rem;
    background: #fff;
    padding: 10px 16px 10px 14px;
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
}
.quickv__container {
    height: 88vh !important;
    overflow-y: scroll !important;
}

.active-drag {
    background: lightgreen !important;
}

.p-0 {
    padding: 0 !important;
}
button.bp3-intent-success {
    font-weight: 600 !important;
    cursor: pointer !important;
    padding: 3px 10px 3px 10px !important;
    border-radius: 2px !important;
    transition: all 0.3s ease !important;
    color: #fff !important;
    background: #08a742 !important;
    border: 1px solid #019c3b !important;
    box-shadow: none !important;
    font-family: inherit !important;
    outline: none !important;
}
button.bp3-intent-success:active {
    outline: none !important;
}
button.bp3-intent-success:hover {
    background: #00bd45 !important;
    border: 1px solid #03bd45 !important;
}
button {
    outline: none !important;
}
button.bp3-intent-primary {
    font-weight: 600 !important;
    cursor: pointer !important;
    padding: 3px 10px 3px 10px !important;
    border-radius: 2px !important;
    transition: all 0.3s ease !important;
    color: #fff !important;
    background: #137cbd !important;
    border: 1px solid #137cbd !important;
    box-shadow: none !important;
    font-family: inherit !important;
    outline: none !important;
}
button.bp3-intent-primary:active {
    outline: none !important;
}
button.bp3-intent-primary:hover {
    background: #106ba3 !important;
    border: 1px solid #106ba3 !important;
}
button {
    outline: none !important;
}
.custom-select:disabled {
    color: #adb5bd;
    background-color: #f8f9fa;
}
.totalcalc {
    background: #f5f7f9;
    font-size: 1.2rem;
    font-weight: 600;
}
.log-container {
    height: 300px;
    border: 1px solid #eee;
    width: 100%;
    padding: 10px;
    overflow-y: auto;
}
.log-container ul {
    padding-left: 0;
    margin-left: 0;
}
.log-container ul li {
    border-left: 2px solid green;
    padding-left: 10px;
    padding-bottom: 4px;
    margin-bottom: 2px;
}
.log-container ul li:hover {
    background-color: #f9f9f9;
    cursor: default;
}
.log-container ul li span {
    color: rgb(133, 133, 133);
    font-size: 12px;
}
.log-record h4 {
    margin-bottom: 0;
}
.log-record small {
    opacity: 0.7;
}
.danger {
    color: rgb(255, 0, 0);
}
.warning {
    color: orange;
}
.danger-circle {
    background: #ff00009e;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-left: 7px;
}
.low-opacity {
    opacity: 0.7;
}
.pink {
    color: #dd637e;
}
.success {
    color: rgb(0, 173, 0);
}
.spinnerWhite .bp3-spinner .bp3-spinner-track {
    stroke: rgba(255, 255, 255, 0.2) !important;
}
.spinnerWhite .bp3-spinner .bp3-spinner-head {
    stroke: rgba(255, 255, 255, 0.8) !important;
}
.new {
    background: #2b98e2;
    font-size: 10px;
    padding: 1px 5px;
    color: #fff;
    border-radius: 4px;
}
.FormGroup {
    margin: 0 0 20px;
    padding: 0;
    border-style: none;
    background-color: #fff;
    will-change: opacity, transform;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
}
.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #fff;
}
.StripeElement--webkit-autofill {
    background: transparent !important;
}
.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}
section.pricing {
    /* background: #007bff;
    background: linear-gradient(to right, #0062e6, #33aeff); */
}

.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
    margin: 1.5rem 0;
}

.pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
}

.pricing .card-price {
    font-size: 3rem;
    margin: 0;
}

.pricing .card-price .period {
    font-size: 0.8rem;
}

.pricing ul li {
    margin-bottom: 1rem;
}

.pricing .text-muted {
    opacity: 0.7;
}

.pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .pricing .card:hover {
        margin-top: -0.25rem;
        margin-bottom: 0.25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
    .pricing .card:hover .btn {
        opacity: 1;
    }
}
.secondaryBtn {
    background: #fff !important;
    border: 2px solid #339ee9 !important;
    color: #339ee9 !important;
}
/* 
-------------------------
     PRICING TABLE 2
-------------------------
*/
.pricing-table {
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
}
.pricing-table td {
    padding: 0px;
    border-left: 1px solid transparent;
}
.pricing-table .pricing-table-list td {
    padding: 10px 8px;
    font-weight: 500;
    text-align: center;
    font-size: 12px;
    background: #f9f9f9;
}
.pricing-table .pricing-table-list .glyphicon-ok {
    color: #68af27;
}
.pricing-table .pricing-table-list .glyphicon-remove {
    color: #c22439;
}
.pricing-table .pricing-table-list td:first-child {
    font-weight: 600;
    text-align: left;
    border-left: 0px;
    font-size: 11px;
    color: #333;
    text-transform: uppercase;
}

.pricing-table .pricing-table-list:nth-child(2n) td {
    background: #f2f2f2;
}

.pricing-table .pricing-table-text h2 {
    font-size: 35px;
    font-weight: 300;
    line-height: 33px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    color: #009ad7;
}
.pricing-table .pricing-table-text p {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #666;
    margin-top: 10px;
}

.pricing-table .pricing-table-item {
    color: #fff;
}
.pricing-table .pricing-table-item .pricing-table-item-head {
    padding: 10px 5px;
    text-align: center;
    background: #009ad7;
}
.pricing-table .pricing-table-item .pricing-table-item-head p {
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 0px;
}
.pricing-table .pricing-table-item .pricing-table-item-head span {
    font-size: 11px;
    font-weight: 400;
}

.pricing-table .pricing-table-item .pricing-table-item-price {
    padding: 15px 10px;
    text-align: center;
    background: #fff;
    color: #009ad7;
}
.pricing-table .pricing-table-item .pricing-table-item-price p {
    font-size: 41px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 0px;
}
.pricing-table .pricing-table-item .pricing-table-item-price p span {
    font-size: 12px;
    font-weight: 300;
}
.pricing-table .pricing-table-item .pricing-table-item-price > span {
    font-size: 12px;
    font-weight: 400;
    color: #009ad7;
}

.pricing-table .pricing-table-item .pricing-table-item-purchase {
    padding: 10px 5px;
    background: #fff;
}
.pricing-table .pricing-table-item .pricing-table-item-purchase .btn {
    text-transform: uppercase;
    margin-bottom: 0px;
}
/* eof pricing table */
